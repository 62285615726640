import axios from 'axios'
import { decryptUtil,encryptUtil } from '../Utils/decrypt'; 
const instance = axios.create({


    // baseURL: 'https://www.taskicabs.com/adminbackend/servicePartner'
    // baseURL: 'https://stagesp.taski.in/web'
    baseURL: 'https://sp.taski.in/web'
    // baseURL: "http://192.168.2.73:2027/web/",
    // baseURL: "http://192.168.2.235:80/web/",
    // baseURL: "http://192.168.2.104:2020/web/",
    // baseURL: "http://192.168.2.116:2027/web/",
    // baseURL:'http://localhost:80/web',
  
    
})

const token = localStorage.getItem('vendorLoginToken');
const branch=JSON.parse(localStorage.getItem('selectedBranch'))
if(token)  instance.defaults.headers.common['token'] = token
if(branch) instance.defaults.headers.common['branch']=branch.id


instance.interceptors.response.use(
    (response) => {
        try {
            const decryptedData = new decryptUtil({ encrypted: response.data }).getDecryptedData();
            response.data = decryptedData;
        } catch (error) {
           console.log(error)
        }
        return response;
    },
    (error) => {
        console.error('Axios Error:', error.message);
        return Promise.reject(error);
    }
);



instance.interceptors.request.use(
    async (config) => {
        console.log(config.data)
        if (config.data && !(config.data instanceof FormData)) {
            const encryptedPayload = new encryptUtil({ decrypt: config.data }).getEncryptedData();
            console.log("encryptedPayload", encryptedPayload)
            config.data = {
                data: encryptedPayload,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



export default instance
    