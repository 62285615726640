const CryptoJS = require('crypto-js')
const key = CryptoJS.enc.Utf8.parse("21083925f7953c1498b13385364c570264b030c7d2eb48f0ba1549e46b913bb7");
const iv = CryptoJS.enc.Utf8.parse("6ea09bc2addad390db2acee2691a003b");
class decryptUtil {
    constructor({ encrypted }) {
        this.encrypted = encrypted.data
        // console.log(encrypted)
    }

    getDecryptedData() {

        // Convert to base64 (optional, if you're transmitting or storing it)
        const base64Encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(this.encrypted));

        // When decrypting, decode from base64 first
        const encryptedData = CryptoJS.enc.Base64.parse(base64Encoded).toString(CryptoJS.enc.Utf8);

        // Decrypt
        const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        });

        const decryptedText = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
        return decryptedText;
    }
}

class encryptUtil {
    constructor({ decrypt }) {
        this.data = decrypt
    }

    getEncryptedData() {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(this.data), key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        });

        const encryptedText = encrypted.toString();
        return encryptedText;
    }
}

module.exports = {
    decryptUtil,
    encryptUtil
};

